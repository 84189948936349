import React from "react";
import { useLocation } from "react-router-dom";

import { useSiteData } from "../SiteDataContext";

import mobileMenuIcon from "../assets/img/icons/mobileMenuIcon.svg";
import avatarIcon from "../assets/img/icons/avatarIcon.svg";

function Footer() {
  const location = useLocation();
  const { pathname } = location;
  const { siteData, setSiteData } = useSiteData();

  return (
    <header className="w-full bg-violet-dark text-white flex items-center justify-center">
      <div className="container p-6 md:p-0 flex flex-col md:justify-between items-center md:flex-row md:h-6-25 w-full gap-3">
        <div className="font-bold f-size-0-875 md:f-size-1-125">Футер</div>
        <div className="font-bold f-size-0-875 md:f-size-1-125">
          Ссылки на соцсети
        </div>
        <div className="f-size-0-8125 md:f-size-1-125 md:font-bold">2024</div>
      </div>
    </header>
  );
}

export default Footer;
