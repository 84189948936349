import React, { useState } from "react";

import circleChecked from "../assets/img/icons/circleChecked.svg";
import circleUnchecked from "../assets/img/icons/circleUnchecked.svg";
import okIcon from "../assets/img/icons/okIcon.svg";
import Popup from "./Popup";
import { REACT_APP_API_URL } from "../config";

function Tests({ lessonData }) {
  const [answers, setAnswers] = useState({});
  const [results, setResults] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [allCorrect, setAllCorrect] = useState(false);
  const [showCompletedPopup, setShowCompletedPopup] = useState(false);
  const [showFailPopup, setShowFailPopup] = useState(false);

  const isLessonCompleted = lessonData.is_active || (submitted && allCorrect);

  const handleAnswerChange = (questionIndex, answerIndex) => {
    if (isLessonCompleted) return;

    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionIndex]: answerIndex,
    }));
    setSubmitted(false);
    setResults(null);
    setAllCorrect(false);
  };

  // Check if all questions have been answered
  const allAnswered = lessonData.tests?.every((item, index) =>
    answers.hasOwnProperty(index)
  );

  const navToMain = () => {
    window.location.href = `/`;
  };

  const handleSubmit = async () => {
    if (isLessonCompleted) {
      const nextStepNumber = lessonData.step_number + 1;
      window.location.href = `/lesson/${nextStepNumber}`;
      return;
    }

    const payload = lessonData.tests.map((test, questionIndex) => {
      const selectedAnswerIndex = answers[questionIndex];
      const selectedAnswer = test.answers[selectedAnswerIndex];
      return {
        test_id: test.id,
        selected_answer_id: selectedAnswer.id,
      };
    });

    try {
      const token = localStorage.getItem("auth_token");
      const response = await fetch(`${REACT_APP_API_URL}/api/check-answer/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ answers: payload }),
      });

      const data = await response.json();

      if (data.status === "success") {
        const resultsMap = {};
        let allAnswersCorrect = true;
        data.results.forEach((result) => {
          resultsMap[result.test_id] = result;
          if (!result.is_correct) {
            allAnswersCorrect = false;
          }
        });
        setResults(resultsMap);
        setSubmitted(true);
        setAllCorrect(allAnswersCorrect);

        if (allAnswersCorrect) {
          try {
            const updateResponse = await fetch(
              `${REACT_APP_API_URL}/api/update-progress/`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ lesson_id: lessonData.id }),
              }
            );
            const updateData = await updateResponse.json();
            if (updateData.status !== "success") {
              alert("Error updating progress: " + updateData.message);
            }
          } catch (error) {
            console.error("Error updating progress:", error);
          }
          setShowCompletedPopup(true);
        } else {
          setShowFailPopup(true);
        }
      } else {
        alert("Error: " + data.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <section className="w-full flex justify-center bg-white-blue">
      <div className="container relative color-black px-1-25 md:py-6-25">
        <div className="max-w-50">
          <h3 className="fw-700 f-size-2 md:f-size-3-75 lh-5-25 mb-3-125">
            Тест
          </h3>

          <div className="mb-3-125">
            {lessonData.tests?.map((item, index) => {
              const testResult = results ? results[item.id] : null;
              return (
                <div key={index}>
                  <div className="fw-700 f-size-1-125 lh-1-575 mb-1-625">
                    {item.text}
                  </div>

                  <div className="mb-3-125">
                    {item.answers?.map((_item, _index) => {
                      let isSelected = answers[index] === _index;

                      let answerClassName =
                        "fw-500 f-size-1-125 lh-2-5875 flex items-center";

                      if (isLessonCompleted) {
                        answerClassName += " cursor-not-allowed opacity-50";
                      } else {
                        answerClassName += " cursor-pointer";
                      }

                      if (_item.is_correct) {
                        isSelected = true;
                      }

                      let icon = isSelected ? circleChecked : circleUnchecked;

                      if (submitted && testResult) {
                        const isUserAnswer = isSelected;
                        const isCurrentAnswerCorrect =
                          _item.id === testResult.selected_answer_id &&
                          testResult.is_correct;

                        if (isUserAnswer && !isCurrentAnswerCorrect) {
                          answerClassName += " text-red-500";
                        }

                        if (
                          allCorrect &&
                          _item.id === testResult.correct_answer_id
                        ) {
                          answerClassName += " text-green-500";
                        }
                      }

                      return (
                        <div
                          key={_index}
                          onClick={
                            !isLessonCompleted
                              ? () => handleAnswerChange(index, _index)
                              : null
                          }
                          className={answerClassName}
                        >
                          <img
                            src={icon}
                            className="wh-1-25 mr-0-9375"
                            alt="present"
                          />
                          {_item.text}
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>

          <button
            className={`f-size-1-5 color-white py-1-5625rem px-20 bg-violet-dark fw-700 ${
              isLessonCompleted || allAnswered ? "active:opacity-90" : ""
            }`}
            disabled={!allAnswered && !isLessonCompleted}
            onClick={handleSubmit}
          >
            {isLessonCompleted ? "Следующий шаг" : "Проверить результат"}
          </button>
        </div>
      </div>

      {showFailPopup ? (
        <Popup
          title="Увы, тест не пройден =("
          greenText="Пройдите тест еще раз или пересмотрите материал урока"
          onClickFirstButton={navToMain}
          primaryButtonText="Попробовать еще"
          onClickPrimaryButton={() => {
            setShowFailPopup(false);
          }}
          onClosePopup={() => {
            setShowFailPopup(false);
          }}
        />
      ) : (
        ""
      )}

      {showCompletedPopup ? (
        <Popup
          title="Поздравляем, урок пройден!"
          bonus={
            <div>
              <p className="text-xl md:text-2xl font-bold mb-1 md:mb-[1.875rem]">
                Бонус за прохождение этого урока
              </p>
              <p className="text-xl md:text-2xl font-bold flex items-center">
                <img src={okIcon} className="wh-2-1875 mr-[1.5rem]" alt="ok" />
                Поднять лимит на 5 конфигов до 300 Гб (1Тб)
              </p>
            </div>
          }
          firstButtonText="Посмотреть ответы"
          onClickFirstButton={() => {
            setShowCompletedPopup(false);
          }}
          onClickPrimaryButton={handleSubmit}
          onClosePopup={() => {
            setShowCompletedPopup(false);
          }}
        />
      ) : (
        ""
      )}
    </section>
  );
}

export default Tests;
