import Header from "../components/Header";
import Footer from "../components/Footer";
import LessonsRibbon from "../components/LessonsRibbon";
import HeroTg from "../components/HeroTg";
import { REACT_APP_API_URL } from "../config";
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Tests from "../components/Tests";

function Lesson() {
  const { lessonId } = useParams();
  const [lessonData, setLessonData] = useState({});

  useEffect(() => {
    const token = localStorage.getItem("auth_token");

    axios
      .get(`${REACT_APP_API_URL}/api/lessons/${lessonId}`, {
        headers: {
          Authorization: `Bearer ${token}`, // Add the token to the Authorization header
        },
      })
      .then((response) => {
        setLessonData(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      });
  }, []);

  return (
    <div className="bg-white">
      <Header />
      <section className="w-full flex justify-center pb-12">
        <div className="container grid grid-cols-2 md:flex md:flex-row cg-0-625 rg-0-75 px-1-25 md:px-0 py-1-25">
          <LessonsRibbon />
        </div>
      </section>

      <section className="w-full flex justify-center pb-5-5275">
        <div className="md:w-46-25 px-1-25 md:px-0">
          <h1 className="fw-700 lh-5-25 mb-4 text-center text-[2rem] md:text-[3.75rem]">
            {lessonData.title}
          </h1>
          <h1 className="fw-700 leading-[1.75rem] md:leading-[2.8rem] text-center mb-2-875 text-[1.25rem] md:text-[2rem]">
            {lessonData.subtitle}
          </h1>

          <div
            className="ck-content"
            dangerouslySetInnerHTML={{ __html: lessonData.content }}
          />
        </div>
      </section>

      <Tests lessonData={lessonData} />

      <HeroTg />
      <Footer />
    </div>
  );
}

export default Lesson;
