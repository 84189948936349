import React, { useEffect, useState } from "react";

function HeroTg() {
  return (
    <section className="w-full flex justify-center bg-white">
      <div className="container relative color-black p-12 pb-[3.75rem] text-center flex flex-col items-center">
        <p className="fw-700 f-size-1-25 mb-12 md:f-size-2 color-black">
          Обратитесь в нашу службу поддержки, чтобы узнать больше
        </p>

        <a
          className="md:mw-24-75 w-full block br-0-3125 bg-green text-white fw-700 f-size-1 p-0-875 md:px-5 md:py-1-5625 md:f-size-1-5"
          target="_blank"
          href="https://t.me/na_svyazi_helpdesk_bot"
        >
          Спроси эксперта
        </a>
      </div>
    </section>
  );
}

export default HeroTg;
