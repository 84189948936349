import React, { act, useEffect, useState } from "react";

import { useSiteData } from "../SiteDataContext";

import presentDarkIcon from "../assets/img/icons/presentDarkIcon.svg";
import presentWhiteIcon from "../assets/img/icons/presentWhiteIcon.svg";

import { REACT_APP_API_URL } from "../config";
import axios from "axios";
import { useParams } from "react-router-dom";


function LessonsRibbon() {
  const { siteData, setSiteData } = useSiteData();
  const [lessons, setLessons] = useState([]);

  const { lessonId } = useParams();

  const authUsername = localStorage.getItem("auth_username", "");

  useEffect(() => {
    const token = localStorage.getItem("auth_token");

    axios
      .get(`${REACT_APP_API_URL}/api/lessons/`, {
        headers: {
          Authorization: `Bearer ${token}`, // Add the token to the Authorization header
        },
      })
      .then((response) => {
        setLessons(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      });
  }, []);

  const activeLessons = lessons.filter(item => item.is_active);

  const lastLessonIndex = activeLessons.length - 1;
  const lastLesson = activeLessons[lastLessonIndex]

  console.log('lastLesson', lastLesson)
  const firstStepNumber = lessons[0]?.step_number;

  let linkhref = '/login'
  if (authUsername) {
    linkhref = `/lesson/${lastLesson?.step_number}`;
  }

  return (
    <>
      {!authUsername ? (
        <div className="flex justify-center items-center w-100p">
          <a
            href={linkhref}
            className="pl-3-375 pr-3-375 bg-green color-white step-button py-0-625 text-center f-size-1-125 fw-700 flex justify-center items-center"
          >
            Сделать шаг
          </a>
        </div>
      ) : (
        <div className="container grid grid-cols-2 md:flex md:flex-row cg-0-625 rg-0-75 px-1-25 md:p-5 md:br-0-9375 md:bg-white">
          {lessons.map((item, index) => {
            const isCurrent = item.step_number == lessonId;
            const isActive = item.is_active;
            return (
              <a
                href={`/lesson/${item.step_number}`}
                className={`${isActive ? "active" : "inactive"} ${isCurrent ? "current" : ""
                  } lesson-button md:w-7-55 step-button py-0-625 text-center f-size-1-125 fw-700 flex justify-center items-center`}
                key={index}
              >
                Шаг {item.step_number}
                {item.has_present ? (
                  <img
                    src={isActive || isCurrent ? presentWhiteIcon : presentDarkIcon}
                    className="ml-0-625 wh-1-21875"
                    alt="present"
                  />
                ) : null}
              </a>
            );
          })}

          <a
            href={linkhref}
            className="pl-3-375 pr-3-375 hidden md:block bg-green color-white step-button py-0-625 text-center f-size-1-125 fw-700 flex justify-center items-center ml-auto"
          >
            Сделать шаг
          </a>


        </div>
      )}

    </>
  );
}

export default LessonsRibbon;
