import React, { useEffect, useState } from "react";

import { useSiteData } from "../SiteDataContext";

import { REACT_APP_API_URL } from "../config";
import axios from "axios";
import LessonsRibbon from "./LessonsRibbon";
import HeroTg from "./HeroTg";

function Hero() {
  const { siteData, setSiteData } = useSiteData();
  const [lessons, setLessons] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem("auth_token");

    axios
      .get(`${REACT_APP_API_URL}/api/lessons/`, {
        headers: {
          Authorization: `Bearer ${token}`, // Add the token to the Authorization header
        },
      })
      .then((response) => {
        setLessons(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      });
  }, []);

  return (
    <main>
      <section className="w-full flex justify-center">
        <div className="container relative color-black px-1-25 md:px-0 pt-4-375 pb-12">
          <div className="md:mw-56-5">
            <h2 className="f-size-2 md:f-size-3-75 font-bold lh-2-7 fw-700 mb-12">
              Путь к свободному интернету начинается с первого шага
            </h2>

            <h3 className="f-size-1-25 md:f-size-1-5 lh-1-75 fw-700 mb-6">
              Узнай, как ускорить интернет и улучшить связь
            </h3>

            <h3 className="f-size-0-875 md:f-size-1-125 lh-1-3125 fw-700 lt-m-0-01">
              А ещё безопасно и спокойно пользоваться интернетом и помогать
              другим
            </h3>
          </div>
        </div>
      </section>

      <section className="w-full flex justify-center pb-12">
        <LessonsRibbon />
      </section>

      <section className="w-full flex justify-center pb-12">
        <div className="container relative flex justify-center md:gap-1-25 flex-col md:flex-row">
          <div className="md:w-1/2 bg-green md:br-1 px-1-25 md:px-0 md:p-3-75 pt-14 pb-6 text-white">
            <h3 className="pb-9 fw-700 f-size-2-625">О чём это?</h3>
            <p className="fw-700 f-size-1-125 lh-1-8">
              Ты узнаешь, как безопасно работать в интернете, как защитить свои
              переписки и данные, как безопасно оплачивать покупки, как
              настроить телевизор, и ещё множество важных ответов, помогающих
              делать интернет свободным и безопасным. А главное -- что делать,
              если случится интернет-шатдаун!
            </p>
          </div>
          <div className="md:w-1/2 bg-green md:br-1 px-1-25 md:px-0 md:p-3-75 pt-8 pb-6 text-white">
            <h3 className="pb-9 fw-700 f-size-2-625">Для кого это?</h3>
            <p className="fw-700 f-size-1-125 lh-1-8">
              Для тех, кто хочет сделать что-то для рунета в свои годы ;) В
              твоих руках ключи к свободному и безопасному интернету, а значит,
              будущее всё-таки зависит именно от тебя!
            </p>
          </div>
        </div>
      </section>

      {/* <section className="w-full flex justify-center bg-green">
        <div className="container relative px-1-25 md:px-0 flex  flex-col-reverse md:flex-row md:gap-15">
          <div className="flex-1">
            <h3 className="color-white fw-700 f-size-2 lh-2-7 px-2 pb-10 md:pb-12 md:f-size-3-375 md:mt-4-375">
              Твои бонусы за прохождение пути
            </h3>
            <div className="relative pl-15 text-white fw-700 f-size-1-25 md:f-size-1-5 lh-1-75 mb-7 flex items-center">
              <img
                src={okIcon}
                className="wh-2-1875 absolute left-0"
                alt="ok"
              />
              Увеличение скорости на 5 конфигов: до 30 мбит/сек
            </div>

            <div className="relative pl-15 text-white fw-700 f-size-1-25 md:f-size-1-5 lh-1-75 mb-7 flex items-center">
              <img
                src={okIcon}
                className="wh-2-1875 absolute left-0"
                alt="ok"
              />
              Поднять лимит на 5 конфигов до 300 Гб (1Тб)
            </div>

            <div className="relative pl-15 text-white fw-700 f-size-1-25 md:f-size-1-5 lh-1-75 mb-7 flex items-center">
              <img
                src={okIcon}
                className="wh-2-1875 absolute left-0"
                alt="ok"
              />
              Личная консультация по цифровой безопасности.
            </div>
          </div>
          <div className="flex items-center justify-center mt-12 mb-10">
            <img src={presentImage} className="present-image" alt="Present" />
          </div>
        </div>
      </section> */}

      <section className="w-full flex justify-center">
        <div className="container relative color-black p-12 text-center">
          <h3 className="fw-700 f-size-2 mb-3 md:mb-12 md:f-size-3-75">
            Маршрут
          </h3>
          <p className="fw-700 f-size-1-25 md:f-size-1-5 lh-1-75">
            А вот и короткие шаги, которые тебе нужно пройти
          </p>
        </div>
      </section>

      <section className="w-full flex justify-center pb-12">
        <div className="container relative px-0-625 md:px-0">
          <div className="gap-[1.5rem] md:gap-y-[1.875rem] md:gap-x-[1.25rem] grid grid-cols-1 md:grid-cols-2">
            {lessons.map((item) => (
              <a
                key={item.id}
                className={`br-0-625 md:flex md:h-14-875rem ${item.is_active ? "card-active" : "card-inactive"
                  }`}
                href={`/lesson/${item.step_number}`}
              >
                <div className="mx-3-125 fw-300 hidden md:flex lg:w-11 items-center justify-center text-6xl lg:f-size-10-625 card-number">
                  0{item.step_number}
                </div>
                <div className="flex-1 p-5 md:pl-0 md:py-3-71875">
                  <p className="fw-700 f-size-1-25 lh-1-75 md:hidden card-step">
                    Шаг 0{item.step_number}
                  </p>
                  <p className="fw-700 text-lg sm:text-base md:text-base md:text-2xl lh-1-75 mb-3 md:mb-6 card-title  break-all">
                    {item.title}
                  </p>
                  <p className="fw-400 f-size-1-125 lh-1-8 mb-6 md:mw-15">
                    {item.subtitle}
                  </p>
                  <div className="flex justify-center md:hidden">
                    <a
                      className="card-btn br-0-3125 w-10-625 h-2-5625 flex flex-center items-center justify-center fw-700 f-size-0-875 lh-1-3125"
                      href=""
                    >
                      Сделать шаг
                    </a>
                  </div>
                </div>
              </a>
            ))}
          </div>
        </div>
      </section>

      <HeroTg />
    </main>
  );
}

export default Hero;
