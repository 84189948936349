export function generateAdjectiveColorNoun() {
  const colors = [
    "красный",
    "оранжевый",
    "желтый",
    "зеленый",
    "голубой",
    "синий",
    "фиолетовый",
    "розовый",
    "коричневый",
    "черный",
    "белый",
    "серый",
    "бирюзовый",
    "золотой",
    "серебряный",
    "пурпурный",
    "бежевый",
    "малиновый",
    "охра",
    "лиловый",
    "янтарный",
    "шоколадный",
    "индиго",
    "лазурный",
    "оливковый",
    "хаки",
    "салатовый",
    "лимонный",
    "изумрудный",
    "неоновый",
    "коралловый",
    "сиреневый",
    "светло-зеленый",
    "темно-синий",
    "горчичный",
    "мятный",
    "песочный",
    "бордовый",
    "маренго",
    "пепельный",
    "льняной",
    "персиковый",
    "кремовый",
    "стальной",
    "фисташковый",
    "терракотовый",
    "аквамариновый",
    "карамельный",
    "кофейный",
    "платиновый",
    "дымчатый",
    "лавандовый",
    "каштановый",
    "гранатовый",
    "темно-зеленый",
    "оливковый",
    "аметистовый",
    "бронзовый",
    "кобальтовый",
    "медный",
    "опаловый",
    "жемчужный",
    "рубиновый",
    "сапфировый",
    "топазовый",
    "умбра",
    "хаки",
    "ультрамариновый",
    "циановый",
    "охристый",
    "фуксия",
    "хромовый",
    "эбеновый",
    "янтарный",
    "багряный",
    "васильковый",
    "графитовый",
    "дымчато-голубой",
    "изысканный",
    "кобальтово-синий",
    "лососевый",
    "нефритовый",
    "пурпурно-красный",
    "радужный",
    "сине-зеленый",
    "титановый",
    "ультрафиолетовый",
    "хризолитовый",
    "ягодный",
    "алый",
    "бриллиантовый",
    "волновой",
    "горный",
    "джинсовый",
    "жемчужно-серый",
    "зеленовато-желтый",
    "индиговый",
    "кадмиево-желтый",
    "лазурно-голубой",
    "малахитовый",
    "нефритово-зеленый",
    "огненный",
    "пастельный",
    "ромашковый"
  ];

  const adjectives = [
    "сообразительный",
    "изобретательный",
    "храбрый",
    "мудрый",
    "ловкий",
    "умный",
    "забавный",
    "отважный",
    "энергичный",
    "благородный",
    "веселый",
    "сильный",
    "быстрый",
    "дружелюбный",
    "терпеливый",
    "искренний",
    "честный",
    "щедрый",
    "талантливый",
    "смелый",
    "ласковый",
    "внимательный",
    "настойчивый",
    "креативный",
    "интеллигентный",
    "активный",
    "организованный",
    "старательный",
    "остроумный",
    "харизматичный",
    "воспитанный",
    "скромный",
    "ответственный",
    "решительный",
    "преданный",
    "справедливый",
    "умелый",
    "уверенный",
    "наблюдательный",
    "деликатный",
    "коммуникабельный",
    "сочувствующий",
    "целеустремленный",
    "оптимистичный",
    "проницательный",
    "благоразумный",
    "великодушный",
    "добродушный",
    "энтузиастичный",
    "усердный",
    "заботливый",
    "лаконичный",
    "выносливый",
    "изящный",
    "непреклонный",
    "прагматичный",
    "разумный",
    "чувствительный",
    "осторожный",
    "проактивный",
    "тактичный",
    "толерантный",
    "уравновешенный",
    "привлекательный",
    "хитрый",
    "добрый",
    "терпимый",
    "обходительный",
    "амбициозный",
    "сосредоточенный",
    "пунктуальный",
    "инициативный",
    "позитивный",
    "рассудительный",
    "восприимчивый",
    "вдумчивый",
    "учтивый",
    "осмотрительный",
    "вдохновляющий",
    "сдержанный",
    "беззаботный",
    "обаятельный",
    "щепетильный",
    "интуитивный",
    "красноречивый",
    "независимый",
    "практичный",
    "предусмотрительный",
    "сознательный",
    "спокойный",
    "настороженный",
    "надежный",
    "трезвый",
    "любознательный",
    "дипломатичный",
    "серьезный",
    "аккуратный",
    "доблестный",
    "увлеченный",
    "артистичный",
    "чудесный",
    "замечательный",
    "приятный",
    "радостный",
    "яркий",
    "находчивый",
    "живой"
  ];

  const animals = [
    "медведь",
    "лис",
    "волк",
    "тигр",
    "кот",
    "орел",
    "слон",
    "собака",
    "кролик",
    "обезьяна",
    "зебра",
    "жираф",
    "носорог",
    "кенгуру",
    "крокодил",
    "бегемот",
    "панда",
    "леопард",
    "гепард",
    "пума",
    "кабан",
    "лось",
    "бобр",
    "олень",
    "еж",
    "утконос",
    "горилла",
    "шимпанзе",
    "попугай",
    "дятел",
    "пингвин",
    "страус",
    "ласка",
    "хорек",
    "сурикат",
    "куница",
    "росомаха",
    "выдра",
    "песец",
    "енот",
    "кит",
    "дельфин",
    "акула",
    "осьминог",
    "кальмар",
    "морж",
    "тюлень",
    "черепаха",
    "змея",
    "лягушка",
    "саламандра",
    "кобра",
    "орлан",
    "ястреб",
    "сокол",
    "курица",
    "петух",
    "утка",
    "гусь",
    "лебедь",
    "ворона",
    "сорока",
    "воробей",
    "ласточка",
    "кукушка",
    "соловей",
    "аист",
    "фламинго",
    "козел",
    "овца",
    "корова",
    "бык",
    "лошадь",
    "осел",
    "муравьед",
    "анаконда",
    "игуана",
    "хамелеон",
    "ленивец",
    "ягуар",
    "рысь",
    "манул",
    "какаду",
    "тукан",
    "иглоног",
    "морской конек",
    "коралл",
    "медуза",
    "краб",
    "омар",
    "креветка",
    "стрекоза",
    "бабочка",
    "пчела",
    "муравей",
    "жук",
    "богомол",
    "светлячок",
    "паук",
    "скорпион",
    "сороконожка",
    "улитка"
  ];

  const randomAdjective =
    adjectives[Math.floor(Math.random() * adjectives.length)];

  const randomColor = colors[Math.floor(Math.random() * colors.length)];

  const randomAnimal = animals[Math.floor(Math.random() * animals.length)];

  return `${randomAdjective} ${randomColor} ${randomAnimal}`;
}

export function generateRandomWords(numWords) {
  const words = [
    "яблоко",
    "банан",
    "вишня",
    "дракон",
    "слон",
    "лиса",
    "жираф",
    "мед",
    "остров",
    "джунгли",
    "воздушный",
    "лимон",
    "гора",
    "ночь",
    "апельсин",
    "ручка",
    "королева",
    "река",
    "солнце",
    "тигр",
    "зонт",
    "вулкан",
    "вода",
    "ксилофон",
    "йогурт",
    "зебра",
  ];

  let randomWords = [];
  for (let i = 0; i < numWords; i++) {
    const randomWord = words[Math.floor(Math.random() * words.length)];
    randomWords.push(randomWord);
  }

  return randomWords.join(" ");
}
