import React from "react";
import { Link } from "react-router-dom";

import { useSiteData } from "../SiteDataContext";

import closeIcon from "../assets/img/icons/closeIcon.svg";

function MobileMenu() {
  const { siteData, setSiteData } = useSiteData();

  const authUsername = localStorage.getItem("auth_username", "");

  const close = () => {
    setSiteData({ ...siteData, isOpenMobileMenuModal: false });
  };

  const handleBackgroundClick = (e) => {
    if (e.target.id === "mobile-menu") {
      close();
    }
  };

  return (
    <div
      id="mobile-menu"
      className={`modal-container p-0 flex justify-start items-center ${siteData.isOpenMobileMenuModal ? "" : "hidden"
        }`}
      onClick={handleBackgroundClick}
    >
      <div className="overflow-auto relative w-[70%] h-full bg-cream p-8">
        <div
          className="inline absolute right-4 top-4 p-0-5 cursor-pointer"
          onClick={close}
        >
          <img className="w-1-125" src={closeIcon} />
        </div>

        <article className="f-size-1 mt-4">
          <div className="flex justify-center mb-2">
            <Link
              to="/"
              onClick={close}
              className="text-center px-1 w-16-25 md:w-21-25 br-0-5 main-button bg-dark-green f-size-1-125 md:f-size-1-5 md:lt-0-02"
            >
              На главную
            </Link>
          </div>

          {authUsername ? (

            <div className="flex justify-center mb-2">
              <Link
                to="/"
                onClick={close}
                className="text-center w-16-25 md:w-21-25 br-0-5 main-button bg-dark-green f-size-1-125 md:f-size-1-5 md:lt-0-02"
              >
                Выйти
              </Link>
            </div>
          ) : ""}



        </article>
      </div>
    </div>
  );
}

export default MobileMenu;
