import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useEffect, useState } from "react";

import Main from "./pages/Main";
import Contacts from "./pages/Contacts";
import About from "./pages/About";

import { SiteDataProvider } from "./SiteDataContext";
import MobileMenu from "./components/MobileMenu";
import NewUser from "./pages/NewUser";
import Login from "./pages/Login";
import Lesson from "./pages/Lesson";

function App() {
  const [siteData, setSiteData] = useState({});

  useEffect(() => {
    // axios
    //   .get(`${REACT_APP_API_URL}/api/site-texts/`)
    //   .then((response) => {
    //     setSiteData(response.data);
    //   })
    //   .catch((error) => {
    //     console.error("There was an error fetching the data!", error);
    //   });
  }, []);

  return (
    <SiteDataProvider siteData={siteData} setSiteData={setSiteData}>
      <Router>
        <MobileMenu />
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/login" element={<Login />} />
          <Route path="/new-user" element={<NewUser />} />
          <Route path="/about" element={<About />} />
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/lesson/:lessonId" element={<Lesson />} />
        </Routes>
      </Router>
    </SiteDataProvider>
  );
}

export default App;
