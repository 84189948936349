import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useSiteData } from "../SiteDataContext";

import mobileMenuIcon from "../assets/img/icons/mobileMenuIcon.svg";
import avatarIcon from "../assets/img/icons/avatarIcon.svg";

function Header() {
  const location = useLocation();
  const { pathname } = location;
  const { siteData, setSiteData } = useSiteData();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const authUsername = localStorage.getItem("auth_username", "");

  const handleLogout = () => {
    // Implement logout logic here, e.g., clear auth token, redirect, etc.
    localStorage.removeItem("auth_token");
    localStorage.removeItem("auth_username");
    window.location.href = "/login";
  };

  return (
    <header className="w-full bg-white">
      <div className="container relative pl-1-25 md:plr-3 mx-auto w-full color-black h-3-125 md:h-6-5625 flex md:justify-center">
        <div className="flex h-100p absolute top-0 right-1-25 md:hidden items-center">

          {authUsername ? (
            <img
              src={avatarIcon}
              className="wh-1-875 mr-2 cursor-pointer"
              alt="Avatar"
            />
          ) : ""}

          <img
            src={mobileMenuIcon}
            className="wh-1-875 cursor-pointer"
            alt="Menu"
            onClick={() =>
              setSiteData({ ...siteData, isOpenMobileMenuModal: true })
            }
          />
        </div>
        <div className="md:w-1/2 flex flex-1 items-center justify-start">
          <div className="cursor-pointer">
            <a href="/" className="flex items-center">
              <h3 className="px-5 md:px-0 f-size-1 md:f-size-2 font-bold">
                Путь кибер-волонтёра
              </h3>
            </a>
          </div>
        </div>
        <div className="md:w-1/2 flex hidden md:flex relative">
          {authUsername ? (
            <div className="flex items-center justify-end w-full text-lg">
              <span className="f-size-1 fw-500 mr-2">{authUsername}</span>

              <div className="relative">
                {/* Avatar and dropdown toggle */}
                <button
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                  className="flex items-center focus:outline-none"
                >
                  <img
                    src={avatarIcon}
                    className="md:wh-2-5 cursor-pointer"
                    alt="Avatar"
                  />
                </button>

                {/* Dropdown menu */}
                {isDropdownOpen && (
                  <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-md z-10">
                    <ul className="py-2 text-gray-700">
                      <li>
                        <button
                          onClick={handleLogout}
                          className="block w-full text-left px-4 py-2 text-red-600 hover:bg-gray-100"
                        >
                          Выйти
                        </button>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          ) : null}

        </div>
      </div>
    </header>
  );
}

export default Header;
